import React, { Component } from 'react';
import Project from './Project';
import {
  bookreport,
  dolphin,
  kalimpong,
  tugboat,
  mso,
  dbvmapper,
  spacebook,
  good,
  culturebook,
} from './lib/projects';
import './MyWork.css';

export default class MyWork extends Component {
  projects = [
    kalimpong,
    bookreport,
    culturebook,
    dolphin,
    tugboat,
    good,
    mso,
    dbvmapper,
    spacebook,
  ];

  render() {
    return (
      <section id="my-work" className="work">
        <Navigation projects={this.projects} />
        <div className="header">
          <h1>My Work</h1>
        </div>
        <div className="line" />
        <div className="projects">
          {this.projects.map((project, i) => <Project key={i} project={project} />)}
        </div>
      </section>
    );
  }
}

class Navigation extends Component {
  render() {
    return (
      <nav>
      </nav>
    );
  }
}
