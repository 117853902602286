import React, { Component } from 'react';
import './Summary.css';

export default class Summary extends Component {
  render() {
    return (
      <section className="summary">
        <div className="stripe" />
        <div className="content-box">
          <p>
            I am a product designer and software developer.
          </p>
          <p>
            I add value to your company by understanding your problems and solving them with working software.
          </p>
          <p>
            My favorite tools are&nbsp;
            <em className="go">go</em>
            <em className="react">react</em>
            <em className="sql">sql</em>
            <em className="k8s">kubernetes</em>and&nbsp;
            <em className="ue4">Unreal Engine 4</em>.
          </p>
        </div>
      </section>
    );
  }
}
