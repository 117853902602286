import bookreportHome from '../img/projects/bookreport/home.png';
import bookreportPurchasingMP4 from '../img/projects/bookreport/purchasing.mp4';
import bookreportPurchasingWebM from '../img/projects/bookreport/purchasing.webm';
import bookreportPurchasingGif from '../img/projects/bookreport/purchasing.gif';
import bookreportAccountingMP4 from '../img/projects/bookreport/accounting.mp4';
import bookreportAccountingWebM from '../img/projects/bookreport/accounting.webm';
import bookreportAccountingGif from '../img/projects/bookreport/accounting.gif';
import bookreportAccountCodeSearchMP4 from '../img/projects/bookreport/account-code-search.mp4';
import bookreportAccountCodeSearchWebM from '../img/projects/bookreport/account-code-search.webm';
import bookreportAccountCodeSearchGif from '../img/projects/bookreport/account-code-search.gif';
import bookreportBudgetsMP4 from '../img/projects/bookreport/budgets.mp4';
import bookreportBudgetsWebM from '../img/projects/bookreport/budgets.webm';
import bookreportBudgetsGif from '../img/projects/bookreport/budgets.gif';
import bookreportBankRecMP4 from '../img/projects/bookreport/bankrec.mp4';
import bookreportBankRecWebM from '../img/projects/bookreport/bankrec.webm';
import bookreportBankRecGif from '../img/projects/bookreport/bankrec.gif';
import bookreportBoardReportsMP4 from '../img/projects/bookreport/board-reports.mp4';
import bookreportBoardReportsWebM from '../img/projects/bookreport/board-reports.webm';
import bookreportBoardReportsGif from '../img/projects/bookreport/board-reports.gif';
import bookreportOrgAdminsMP4 from '../img/projects/bookreport/org-admin.mp4';
import bookreportOrgAdminsWebM from '../img/projects/bookreport/org-admin.webm';
import bookreportOrgAdminsGif from '../img/projects/bookreport/org-admin.gif';
import bookreportSpendAnalysis from '../img/projects/bookreport/spend-analysis.png';

import dolphinHome from '../img/projects/dolphin/dolphin-home.png';
import dolphinAnalytics from '../img/projects/dolphin/dolphin-analytics.png';
import dolphinHailMP4 from '../img/projects/dolphin/hail.mp4';
import dolphinHailGIF from '../img/projects/dolphin/hail.gif';
import dolphinHailWebM from '../img/projects/dolphin/hail.webm';
import dolphinWindMP4 from '../img/projects/dolphin/wind.mp4';
import dolphinWindGIF from '../img/projects/dolphin/wind.gif';
import dolphinWindWebM from '../img/projects/dolphin/wind.webm';
import dolphinPointsnappingMP4 from '../img/projects/dolphin/pointsnapping.mp4';
import dolphinPointsnappingGIF from '../img/projects/dolphin/pointsnapping.gif';
import dolphinPointsnappingWebM from '../img/projects/dolphin/pointsnapping.webm';
import dolphinUploadMP4 from '../img/projects/dolphin/upload.mp4';
import dolphinUploadGIF from '../img/projects/dolphin/upload.gif';
import dolphinUploadWebM from '../img/projects/dolphin/upload.webm';
import dolphinCheckoutMP4 from '../img/projects/dolphin/checkout.mp4';
import dolphinCheckoutGIF from '../img/projects/dolphin/checkout.gif';
import dolphinCheckoutWebM from '../img/projects/dolphin/checkout.webm';
import dolphinTilesMP4 from '../img/projects/dolphin/tiles.mp4';
import dolphinTilesGIF from '../img/projects/dolphin/tiles.gif';
import dolphinTilesWebM from '../img/projects/dolphin/tiles.webm';

import kpSteamTrailerMP4 from '../img/projects/kalimpong/kp-steam-trailer.mp4';
import kpSteamTrailerWebM from '../img/projects/kalimpong/kp-steam-trailer.webm';
import kpSteamTrailerGif from '../img/projects/kalimpong/kp-steam-trailer.gif';
import kpHome from '../img/projects/kalimpong/kp-full-screen.png';
import kpAtleyMP4 from '../img/projects/kalimpong/kp-atley.mp4';
import kpAtleyGIF from '../img/projects/kalimpong/kp-atley.gif';
import kpAtleyWebM from '../img/projects/kalimpong/kp-atley.webm';
import kpCity2MP4 from '../img/projects/kalimpong/kp-city-2.mp4';
import kpCity2GIF from '../img/projects/kalimpong/kp-city-2.gif';
import kpCity2WebM from '../img/projects/kalimpong/kp-city-2.webm';
import kpCityMP4 from '../img/projects/kalimpong/kp-city.mp4';
import kpCityGIF from '../img/projects/kalimpong/kp-city.gif';
import kpCityWebM from '../img/projects/kalimpong/kp-city.webm';
import kpExhaustMP4 from '../img/projects/kalimpong/kp-exhaust.mp4';
import kpExhaustGIF from '../img/projects/kalimpong/kp-exhaust.gif';
import kpExhaustWebM from '../img/projects/kalimpong/kp-exhaust.webm';
import kpFlipshotMP4 from '../img/projects/kalimpong/kp-flipshot.mp4';
import kpFlipshotGIF from '../img/projects/kalimpong/kp-flipshot.gif';
import kpFlipshotWebM from '../img/projects/kalimpong/kp-flipshot.webm';
import kpGoodshotMP4 from '../img/projects/kalimpong/kp-goodshot.mp4';
import kpGoodshotGIF from '../img/projects/kalimpong/kp-goodshot.gif';
import kpGoodshotWebM from '../img/projects/kalimpong/kp-goodshot.webm';
import kpTailwhipMP4 from '../img/projects/kalimpong/kp-tailwhip.mp4';
import kpTailwhipGIF from '../img/projects/kalimpong/kp-tailwhip.gif';
import kpTailwhipWebM from '../img/projects/kalimpong/kp-tailwhip.webm';
import kpWorldMP4 from '../img/projects/kalimpong/kp-world.mp4';
import kpWorldGIF from '../img/projects/kalimpong/kp-world.gif';
import kpWorldWebM from '../img/projects/kalimpong/kp-world.webm';
import kpAutolanderMP4 from '../img/projects/kalimpong/kp-autolander.mp4';
import kpAutolanderGIF from '../img/projects/kalimpong/kp-autolander.gif';
import kpAutolanderWebM from '../img/projects/kalimpong/kp-autolander.webm';

import tugboatMP4 from '../img/projects/tugboat/tugboat.mp4';
import tugboatGIF from '../img/projects/tugboat/tugboat.gif';
import tugboatWebM from '../img/projects/tugboat/tugboat.webm';

import msoLogo from '../img/projects/mso/logo.png';
import dbvmapperHome from '../img/projects/dbvmapper/dbvmapper.png';
import goodLogo from '../img/projects/good/good-logo.jpg';

import spacebookIcon from '../img/projects/spacebook/spacebook-icon.png';
import spacebookDetail from '../img/projects/spacebook/spacebook-detail.png';
import spacebookFullscreen from '../img/projects/spacebook/spacebook-fullscreen.png';
import spacebookGrid from '../img/projects/spacebook/spacebook-grid.png';
import spacebookIPhone from '../img/projects/spacebook/spacebook-iphone.png';
import spacebookSearch from '../img/projects/spacebook/spacebook-search.png';
import spacebookShuttle from '../img/projects/spacebook/spacebook-shuttle.png';

import culturebookHome from '../img/projects/culturebook/home.png';
import culturebookMP4 from '../img/projects/culturebook/culturebook.mp4';
import culturebookWebM from '../img/projects/culturebook/culturebook.webm';
import culturebookGIF from '../img/projects/culturebook/culturebook.gif';

export const bookreport = {
  name: 'Bookreport',
  role: 'Co-founder & CTO',
  summaryIndefiniteArticle: 'a',
  summary: 'new kind of K-12 financial ERP',
  url: 'http://bookreport.io',
  accent: '#33ba8a',
  description: `As co-founder and CTO of Bookreport I do design, programming, dev ops, and hiring.`,
  slides: [{
    url: bookreportHome,
    title: 'K-12 Financial ERP',
    description: `Bookreport is a financial ERP for schools integrating
                  purchasing, approvals, accounting, HR, reporting, and budgeting.
                  I wrote the API in <strong>go</strong> and built the web client with
                  <strong>React</strong> and <strong>Relay</strong>.`,
    isVideo: false,
  }, {
    url: bookreportPurchasingMP4,
    mp4: bookreportPurchasingMP4,
    webm: bookreportPurchasingWebM,
    gif: bookreportPurchasingGif,
    youtube: 'oqLUJBZex2k',
    title: 'Purchasing',
    description: `The purchasing system lets teachers search for and request what they need,
                  decide which budget to use, and specify the org role that should approve the request.
                  Introducing this feature set saved our customers time and paper.`,
    isVideo: true,
    style: { width: '115.7%', marginLeft: '-3.75%', },
  }, {
    url: bookreportAccountingMP4,
    mp4: bookreportAccountingMP4,
    webm: bookreportAccountingWebM,
    gif: bookreportAccountingGif,
    youtube: 'dqupUJzTKDk',
    title: 'Accounting',
    description: `I built Bookreport's accounting system, which lets accountants search and manage the books, and
                  prevents errors through data validation and self-healing. It integrates with purchasing
                  (and everything else in Bookreport) which saves time and prevents errors.`,
    isVideo: true,
    style: { width: '106.67%', marginLeft: '-4.583%', },
  }, {
    url: bookreportBankRecMP4,
    mp4: bookreportBankRecMP4,
    webm: bookreportBankRecWebM,
    gif: bookreportBankRecGif,
    youtube: 'A2L-Uq5XZJg',
    title: 'Bank Rec',
    description: `
      We wrote a bank reconciliation application to reduce the time it takes to do
      a bank rec from 24 hours to 20 mins. There were some good queries and fun UX challenges
      to getting this right.`,
    isVideo: true,
    style: { width: '106%', marginLeft: '-6%', },
  }, {
    url: bookreportAccountCodeSearchMP4,
    mp4: bookreportAccountCodeSearchMP4,
    webm: bookreportAccountCodeSearchWebM,
    gif: bookreportAccountCodeSearchGif,
    youtube: 'hEF2x1d6sqk',
    title: 'Account Code Smart Search',
    description: `This was a very tricky problem to solve because there are so many moving parts,
                  but I'm happy with how it came out. The searching and matching of account codes
                  is actually executed on the client itself, so it's always responsive. The data source
                  is cached but invalidated by any new codes entering the system. It's easy to find
                  the account code you're looking for, the UI auto-populates missing values for you,
                  and you can't make an error.`,
    isVideo: true,
    style: { width: '138.89%', marginLeft: '-12.22%', marginTop: '-6%' },
  }, {
    url: bookreportBudgetsMP4,
    mp4: bookreportBudgetsMP4,
    webm: bookreportBudgetsWebM,
    gif: bookreportBudgetsGif,
    youtube: 'ZWR4eNaU2qg',
    title: 'Budgets',
    description: `We made it easy for schools to see how their budgets are doing in real-time,
                  and to compare their spending across time periods. I liked the challenge
                  of collecting all of the various events that can impact a budget, maintaining budget
                  integrity (through tests and health-checks), and making them fast.`,
    isVideo: true,
    style: { width: '105.56%', marginLeft: '-4.167%', },
  }, {
    url: bookreportBoardReportsMP4,
    mp4: bookreportBoardReportsMP4,
    webm: bookreportBoardReportsWebM,
    gif: bookreportBoardReportsGif,
    youtube: 'Tfitx6SICCQ',
    title: 'Board Reports',
    description: `The reports are valuable but difficult to get right, from both the technical
                  and user experience perspectives.
                  I wrote extensive test coverage and added health-checks for many of these reports
                  so that they could be easily configured and still behave reliably throughout the fiscal year.`,
    isVideo: true,
    style: { width: '106.94%', marginLeft: '-6.944%', },
  }, {
    url: bookreportOrgAdminsMP4,
    mp4: bookreportOrgAdminsMP4,
    webm: bookreportOrgAdminsWebM,
    gif: bookreportOrgAdminsGif,
    youtube: 'n-Euj6Hl0iw',
    title: 'Organization Admin',
    description: `It became apparent that a nice organization management experience was essential.
                  There are a lot of customizable features so it had to be easy to
                  (1) understand the configuration options and (2) easily find
                  something that you needed to change for your organization.`,
    isVideo: true,
    style: { width: '106.94%', marginLeft: '-6.944%', },
  }, {
    url: bookreportSpendAnalysis,
    title: 'a bunch of other stuff',
    description: `There are at least a dozen more interesting problems that I loved solving while building
                  Bookreport, from UX work to building tools for automating cluster operations with Kubernetes.
                  This list is already long enough though.`,
    isVideo: false,
  }],
  tags: [
    'golang',
    'react.js',
    'relay.js',
    'mysql',
    'elasticsearch',
    'graphql',
    'kubernetes',
    'dev ops',
    'product',
    'design',
    'leadership',
    'accounting',
    'education',
  ],
}

export const dolphin = {
  name: 'Dolphin AI',
  role: 'Co-founder & CTO',
  summaryIndefiniteArticle: 'an',
  summary: 'Artificial Intelligence platform for the Insurance industry',
  url: 'http://dolphinai.com',
  accent: '#73bcc0',
  description: `
    I was co-founder and CTO of Dolphin AI, where I did product design,
    programming, data engineering, dev ops, and machine learning and data science.
  `,
  slides: [{
    url: dolphinHome,
    title: 'Dolphin AI',
    description: `I led the engineering and data science team,
                  built a data engineering pipeline for labeling data and training models,
                  and trained and deployed CV models for detecting damage to buidings.`,
  }, {
    url: dolphinAnalytics,
    title: 'Damage Analytics',
    description: `We were focused on detecting hail and wind damage to shingled residential roofs in the US.
                  Our data science team and I developed models that could detect damage with over 90% accuracy
                  using drone or handheld photography.`,
  }, {
    url: `${dolphinHailMP4}#t=3.5`,
    mp4: dolphinHailMP4,
    webm: dolphinHailWebM,
    gif: dolphinHailGIF,
    youtube: 'k9EKJ3x_3FM',
    title: 'Automatic Hail Damage Detection',
    description: `Detecting hail damage was difficult. We applied transfer learning to existing
                  CV models for general feature detection, and introduced new model architectures into
                  the ensemble to improve detection accuracy.
                  Everybody benefits if storm damage is detected and classified quickly.`,
    isVideo: true,
    style: { width: '121.94%', marginLeft: '-11.11%', marginTop: '-8.5%' },
  }, {
    url: `${dolphinWindMP4}#t=2`,
    mp4: dolphinWindMP4,
    webm: dolphinWindWebM,
    gif: dolphinWindGIF,
    youtube: 'rMjAWlxTTck',
    title: 'Automatic Wind Damage Detection',
    description: `We were also interested in finding wind damage from aerial and handheld photography.
                  I designed a data engineering pipeline to assign labeling tasks to human workers, and an
                  automatic method for passing the highest quality annotations into our training and validation pipeline.`,
    isVideo: true,
    style: { width: '121.94%', marginLeft: '-11.11%', marginTop: '-8%' },
  }, {
    url: `${dolphinPointsnappingMP4}#t=2`,
    mp4: dolphinPointsnappingMP4,
    webm: dolphinPointsnappingWebM,
    gif: dolphinPointsnappingGIF,
    youtube: 'ex_6gRwmDio',
    title: 'Measurement Tool',
    description: `Our customers needed to convert aerial photography into orthorectified imagery,
                  and then measure the structures in those images. I built a platform that ingested
                  large datasets of aerial photography, generated navigable ortho-photos, and provided measurement
                  and annotation UI tools.`,
    isVideo: true,
    style: { width: '111.11%', marginLeft: '-9.72%', marginTop: '-6.944%' },
  }, {
    url: dolphinUploadMP4,
    mp4: dolphinUploadMP4,
    webm: dolphinUploadWebM,
    gif: dolphinUploadGIF,
    youtube: 'BZhii6ZIrqc',
    title: 'Performant Multiple File Uploader',
    description: `I was using the standard JS file upload libraries (which are great!) until a customer uploaded
                  a set of images so large their browser crashed. That night I wrote an uploader
                  that could handle 10GB+ of concurrent image uploads without dying.`,
    isVideo: true,
    style: { width: '111.11%', marginLeft: '-9.72%', marginTop: '-16.0%' },
  }, {
    url: dolphinCheckoutMP4,
    mp4: dolphinCheckoutMP4,
    webm: dolphinCheckoutWebM,
    gif: dolphinCheckoutGIF,
    youtube: 'SgWjUFxjiPM',
    title: 'Checkout UX',
    description: `We used Stripe to handle subscriptions and billing. Making a checkout flow
                  is pretty boring so I tried to make it more enjoyable by making it look nice.`,
    isVideo: true,
    style: { width: '100%', marginTop: '-1%' },
  }, {
    url: dolphinTilesMP4,
    mp4: dolphinTilesMP4,
    webm: dolphinTilesWebM,
    gif: dolphinTilesGIF,
    youtube: 'luZe53e8CxM',
    title: 'Shingle / Tile Detection by Harald & John',
    description: `I didn't create the neural network that can identify individual shingles and tiles
                  – my colleagues Harald Ruda and John Cast did – but this animation exemplifies the work
                  we were doing at Dolphin.`,
    isVideo: true,
    style: { width: '111.11%', marginLeft: '-9.72%', marginTop: '-6.944%' },
  }],
  tags: [
    'golang',
    'c++',
    'react.js',
    'relay.js',
    'python',
    'mysql',
    'elasticsearch',
    'kubernetes',
    'graphql',
    'dev ops',
    'design',
    'drones',
    'machine learning',
    'text extraction',
    'computer vision',
    'leadership',
  ],
}

export const kalimpong = {
  name: 'King of Kalimpong',
  role: 'Creator & Programmer',
  url: 'https://store.steampowered.com/app/1989110/King_of_Kalimpong/',
  summaryIndefiniteArticle: 'a',
  summary: 'fast-paced team-based online multiplayer vehicle/movement shooter',
  accent: '#df8c36',
  description: `
    I design and build King of Kalimpong in Unreal Engine 4 C++. The concept is simple:
    You're a fast-moving minivan with a projectile weapon. Shoot rocks at your friends and
    capture their crown. Boost, jump, dodge, and flip to survive. Block projectiles with
    well-timed kicks, and smash other vehicles to bits when they get in your way. 
    The primary technical challenge is delivering good-feeling gameplay for non-deterministic
    physics simulations moving at the speed of the network.`,
  slides: [/*{
    url: kpHome,
    title: "King of Kalimpong",
    description: `I'm building King of Kalimpong in UE4 because it's a great engine, and because I need to
                  be able to customize the networking and physics behaviors to solve the problem of
                  hiding real-world latency.
                  <br /><strong><i>3D Art by <a href="https://mrhughesdesign.com/" target="_blank" rel="noopener noreferrer">Michael Hughes</a>.</i></strong>`,
    style: { width: '89%', margin: '0px auto', display: 'block' },
  },*/{
      url: kpSteamTrailerMP4,
      mp4: kpSteamTrailerMP4,
      webm: kpSteamTrailerWebM,
      gif: kpSteamTrailerGif,
      youtube: 'nNyFJznmeTI',
      title: 'Team-Based Online Multiplayer',
      description: `
        Implementing client-side prediction with server authoritative movement and
        server-side anticheat for non-deterministic physics movement is the most challenging
        problem I've worked on (on top of just making a game, which for me is hard enough).
        I also wrote a party matchmaking and dedicated hosting infrastructure coordinator (in go)
        to allow players to play together online.`,
      isVideo: true,
      // style: { width: 800, marginLeft: -70, marginTop: -50 },
    }/*, {
      url: kpAutolanderMP4,
      mp4: kpAutolanderMP4,
      webm: kpAutolanderWebM,
      gif: kpAutolanderGIF,
      youtube: 'JiA27W3W7EE',
      title: 'Vehicle Auto-Lander',
      description: `Players probably won't jump if they don't think they'll land well. Actually I know they won't
                  because they all told me so after the playtest that introduced the jump feature. To fix this, I created
                  a system that predicts where the vehicle is going to land and adjusts its angular velocity
                  progressively to guarantee a smooth landing.`,
      isVideo: true,
      // style: { width: '100%', marginLeft: '-25.97%', marginTop: '-18.194%' },
    }, {
      url: `${kpCity2MP4}#t=1`,
      mp4: kpCity2MP4,
      webm: kpCity2WebM,
      gif: kpCity2GIF,
      youtube: 'gUulEl-eDzw',
      title: 'Networked Multiplayer Battle',
      description: `Since the projectiles are large, physics-simulating, and fast moving, they require a lot of attention
                  in terms of network behavior. It's essential to keep them moving smoothly and in nearly the same
                  position across timelines.`,
      isVideo: true,
      // style: { width: 800, marginLeft: -70, marginTop: -50 },
    }, {
      url: kpAtleyMP4,
      mp4: kpAtleyMP4,
      webm: kpAtleyWebM,
      gif: kpAtleyGIF,
      youtube: '-37wgMHANFM',
      title: 'Vehicle on Vehicle Impacts',
      description: `Vehicle collisions are tricky because (1) they're a type of hilarious weapon and (2) position-predicting clients
                  can both declare themselves the winner in their respective simulations. Choosing the winner
                  on the server without requiring that a destroyed vehicle rebuild itself is a fun problem.`,
      isVideo: true,
      // style: { width: 800, marginLeft: -70, marginTop: -50 },
    }, {
      url: kpGoodshotMP4,
      mp4: kpGoodshotMP4,
      webm: kpGoodshotWebM,
      gif: kpGoodshotGIF,
      youtube: 'VQ7yA-fWQRI',
      title: 'Mid-air Movement',
      description: `I want players to feel both vulnerable and dangerous in almost any situation, so they're able to
                  maneuver and shoot in mid-air. This means that the vehicle's movement and position,
                  the projectile's release, and the projectile's trajectory must remain accurate in order to
                  keep the experience from falling apart.`,
      isVideo: true,
      // style: { width: 800, marginLeft: -70, marginTop: -50 },
    }, {
      url: kpFlipshotMP4,
      mp4: kpFlipshotMP4,
      webm: kpFlipshotWebM,
      gif: kpFlipshotGIF,
      youtube: 'rMuwOfmTb0k',
      title: 'Camera Work',
      description: `I really love working on the vehicle camera – both the autonomous behavior of the camera
                  and what the player can do with it. I've researched the camera work from the game <i>Journey</i>
                  extensively and have been applying what I've learned to King of Kalimpong's vehicle camera.`,
      isVideo: true,
      // style: { width: 800, marginLeft: -70, marginTop: -50 },
    }, {
      url: kpTailwhipMP4,
      mp4: kpTailwhipMP4,
      webm: kpTailwhipWebM,
      gif: kpTailwhipGIF,
      youtube: '0b-MFjX5vmA',
      title: 'Co-op and Teammates',
      description: `My favorite game modes are the team modes, and I've been experimenting with more ways for
                  teammates to cooperate. We're testing the gameplay effects of players being able to join
                  their teammate's vehicle as their gunner, and then attaching additional teammates as towed gun trailers.`,
      isVideo: true,
      // style: { width: 800, marginLeft: -70, marginTop: -50 },
    }, {
      url: kpWorldMP4,
      mp4: kpWorldMP4,
      webm: kpWorldWebM,
      gif: kpWorldGIF,
      youtube: 'APH9diFu9ak',
      title: 'Environment Design',
      description: `We want the game worlds to be beautiful and diverse. We're not there yet,
                  but it's a satisfying problem to work on.`,
      isVideo: true,
      // style: { width: 800, marginLeft: -70, marginTop: -50 },
    }, {
      url: kpExhaustMP4,
      mp4: kpExhaustMP4,
      webm: kpExhaustWebM,
      gif: kpExhaustGIF,
      youtube: 'ZmHBYCMcyyM',
      title: 'Split Screen',
      description: `I want my nephews to be able to sit on the couch and play online with
                  their aunt and uncles on PS4 or Steam.`,
      isVideo: true,
      // style: { width: 800, marginLeft: -70, marginTop: -50 },
    }*/],
  tags: [
    'unreal engine 4',
    'c++',
    'golang',
    'networking',
    'steam',
    'math',
    'physics',
    '3d',
    'game design',
    'game development',
    'product',
    'gaming',
  ],
}

export const tugboat = {
  name: 'Tugboat',
  role: 'Author',
  summaryIndefiniteArticle: 'an',
  summary: 'opinionated Kubernetes cluster management tool',
  accent: '#0099ff',
  description: `
    I wrote Tugboat to simplify the management of Bookreport's customers' <strong>kubernetes</strong> clusters.
    It was becoming painful to manage multiple clusters manually, so I wrote a small <strong>go</strong> program
    to make it easy to monitor, modify, inspect, and dump data from any of our customer's clusters.`,
  slides: [{
    url: `${tugboatMP4}#t=3`,
    mp4: tugboatMP4,
    webm: tugboatWebM,
    gif: tugboatGIF,
    youtube: '8sxmJQbVI2U',
    title: "CLI",
    description: `Testing load and features on a per customer basis and diagnosing specific issues
                  became much easier with Tugboat because our dev team was able to switch contexts instantly,
                  and could be confident that each cluster context fit our generalized model.`,
    isVideo: true,
    // style: { width: 623, margin: '0px auto', display: 'block' },
  }],
  tags: [
    'golang',
    'kubernetes',
  ],
}

export const good = {
  name: 'GOOD.is',
  url: 'https://www.good.is/',
  role: 'Senior Developer',
  summaryIndefiniteArticle: 'a',
  summary: 'civic-minded media company',
  accent: '#009DFF',
  description: `
    I worked on the product team at GOOD for 3 years. I learned a lot about
    writing actual production code, solving performance problems at scale, and working
    on a professional dev team.`,
  slides: [{
    url: goodLogo,
    title: "GOOD.is",
    description: `I wrote a lot of code in different lanauges at GOOD and worked on a bunch of different products.
                  While I was there, the company transformed from a magazine into a social network. Building
                  that product and solving the performance issues for a high traffic, interactive experience
                  was a good learning experience for me. I learned that it's often better to think than to type. But
                  then also that you will still probably have to type a lot.`,
    style: { margin: '-2% 0 0' },
  }],
  tags: [
    'golang',
    'ruby',
    'rails',
    'php',
    'javascript',
    'redis',
    'mysql',
    'aws',
    'design',
    'product',
    'process',
    'leadership',
  ],
}

export const mso = {
  name: 'Matthews South',
  url: 'http://matthewssouth.com/',
  role: 'Developer',
  summaryIndefiniteArticle: 'an',
  summary: 'independent capital markets software + advisory firm',
  accent: '#2ea3f2',
  description: `
    I worked with CEO Vijay Culas (former Head of ECM at Goldman Sachs)
    to develop a product that let the team and their clients interact
    directly with the share repurchase models they developed.`,
  slides: [{
    url: msoLogo,
    title: "API & Web client",
    description: `I built a Python API and a reactive JS client app on top of the
                  analytical models the Matthews South team had developed.
                  Making the UI and the API work well together was a fun challenge,
                  because many of the inputs are also the outputs. That probably sounds
                  complicated. It was! But I really like how it all came out.`,
    style: { margin: '-5% 0 0' },
  }],
  tags: [
    'python',
    'javascript',
    'mongodb',
    'design',
    'product',
    'process',
  ],
}

export const dbvmapper = {
  name: 'DBV Mapper',
  role: 'Developer',
  accent: '#34495d',
  summaryIndefiniteArticle: 'a',
  summary: 'building violation notification service',
  description: `
    I built DBV Mapper for a client. I wrote the API in <strong>go</strong>
    and developed the web app in <strong>react</strong>. The app plots building violations
    on a searchable map so you don't have to dig through the NYC buildings database to figure
    out what you need to fix.`,
  slides: [{
    url: dbvmapperHome,
    title: "Building Violations Single-Page Application",
    description: `DBV Mapper consumes from the NYC buildings and violations API, geocodes and plots
                  the violations on a map, and adds them to a search index. Subscribers receive
                  notifications if their building shows up so they can act quickly.`,
    style: { width: '104.5%' },
  }],
  tags: [
    'golang',
    'react.js',
    'relay.js',
    'mysql',
    'elasticsearch',
    'kubernetes',
    'design',
    'product',
  ],
}

export const culturebook = {
  name: 'The Black Tux | Culture Book',
  accent: '#9DB7A6',
  role: 'Developer',
  url: 'https://culture.theblacktux.com',
  summaryIndefiniteArticle: 'a',
  summary: 'guide to working together',
  description: `
    I did the programming for The Culture Book.
    It was fun to make a mobile web app that feels good.`,
  slides: [{
    url: culturebookHome,
    title: "Work, together",
    description: `I built The Culture Book with React and we used a Google Sheet as our CMS.`,
    // style: { width: '104.5%' },
  }, {
    url: culturebookMP4,
    mp4: culturebookMP4,
    webm: culturebookWebM,
    gif: culturebookGIF,
    youtube: 'r1hZiZubA08',
    title: 'User Experience',
    description: `I'm happy with the feel of the app and its performance.
                  I used a similar pre-loading technique for views as iOS uses for its
                  table cells, where nearby, soon-to-be-viewed content is loaded and
                  content you probably won't see soon is unloaded.`,
    isVideo: true,
    // style: { width: '115.7%', marginLeft: '-3.75%', },
  }],
  tags: ['react.js'],
}

export const spacebook = {
  name: 'Spacebook',
  role: 'Creator & Programmer',
  accent: '#30578c',
  summaryIndefiniteArticle: 'a',
  summary: 'collection of 6000+ high resolution photos of space for iOS',
  description: `
    I built Spacebook because I like looking at pictures of space, and I wanted to learn
    how to make an iOS app. I contacted the NASA Astronomy Photo people and asked if I could make
    an app to display their images on iPhone and iPad, and they said yes.
    Sadly, I haven't made time to maintain the app so it is no longer available.
    If you would like to revive it, you should tell me.`,
  slides: [{
    url: spacebookIcon,
    title: "Spacebook",
    description: `My friend <strong><i><a href="http://www.etling.com/" target="_blank" rel="noopener noreferrer">Will Etling</a></i></strong>
                  designed this nice logo for Spacebook.`,
    style: { width: '55.5%', display: 'block', margin: '0px auto' },
  }, {
    url: spacebookDetail,
    title: "Photo Details",
    description: `It's nice to know what you're looking at, even if it is super far away.`,
    style: { width: '61.11%', display: 'block', margin: '0px auto' },
  }, {
    url: spacebookFullscreen,
    title: "Fullscreen",
    description: `Some of the photos are very high res that it's nice to see them in fullscreen mode.`,
    style: { width: '75%', display: 'block', margin: '0px auto' },
  }, {
    url: spacebookGrid,
    title: "Grid",
    description: `I can't make "grid" sound too exciting. Grid!`,
    style: { width: '71.944%', display: 'block', margin: '0px auto' },
  }, {
    url: spacebookSearch,
    title: "Search",
    description: `I found myself wanting to return to specific photos so
                  I added favorites and search.`,
    style: { width: '83.33%', display: 'block', margin: '0px auto' },
  }, {
    url: spacebookShuttle,
    title: "iPhone",
    description: `I thought it was better on the iPad but it's still nice-looking on the iPhone.`,
    style: { width: '35.833%', display: 'block', margin: '0px auto' },
  }, {
    url: spacebookIPhone,
    title: "iPhone",
    description: `Sometimes you <strong><i><u>really</u></i></strong> want to know what you're looking at.`,
    style: { width: '37.5%', display: 'block', margin: '0px auto' },
  }],
  tags: [
    'objective-c',
    'ruby',
    'rails',
    'mysql',
    'redis',
    'aws',
    'design',
    'product',
  ],
}

// good
// ----------
// piggybank
// polyfill
// burner
// black tux
//
