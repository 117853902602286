import React, { Component } from 'react';
import { Row, Input, Button } from 'antd';
import './ContactMe.css';
const { TextArea } = Input;

export default class ContactMe extends Component {
  render() {
    return (
      <section id="contact-me" className="contact">
        <form action="https://formspree.io/nick@piinecone.com" method="POST">
          <h1>Contact Me</h1>
          <Row>
            <label>Email Address</label>
            <Input
              placeholder="nick@eggslist.com"
              name="email"
              size="large"
              style={{ maxWidth: 300, width: '100%' }}
            />
          </Row>
          <Row style={{ marginTop: 24 }}>
            <label>Message</label>
            <TextArea
              placeholder="Local yokels scramble to launch eggslist."
              name="message"
              size="large"
              rows={5}
              style={{ width: 480 }}
            />
          </Row>
          <Row style={{ marginTop: 24 }}>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              icon="message">
              Send Message
            </Button>
          </Row>
        </form>
      </section>
    );
  }
}
