import React, { Component } from 'react';
import Hero from './Hero';
import Summary from './Summary';
import MyWork from './MyWork';
import ContactMe from './ContactMe';
import './App.css';
import './Responsive.css';

class App extends Component {
  render() {
    return (
      <div className="app">
        <Hero />
        <Summary />
        <MyWork />
        <ContactMe />
      </div>
    );
  }
}

export default App;
