import React, { Component } from 'react';
import { smoothScroll } from './lib/scroll';
import { Icon, Tooltip } from 'antd';
import './Hero.css';

export default class Hero extends Component {
  handleSeeMyWorkClick = () => {
    const rect = document.getElementById('my-work').getBoundingClientRect();
    smoothScroll(rect.top, 1000);
  }

  handleContactMeClick = () => {
    const rect = document.getElementById('contact-me').getBoundingClientRect();
    smoothScroll(rect.top, 1000);
  }

  render() {
    return (
      <section className="hero">
        <div className="title">
          <h2>
            <span>Hi, I'm Nick.</span>
            <Tooltip title="Contact me">
              <a className="contact-me" onClick={this.handleContactMeClick}>
                <Icon type="message" />
              </a>
            </Tooltip>
            <Tooltip title="View my github profile">
              <a className="social" target="_blank" rel="noopener noreferrer" href="https://github.com/piinecone">
                <Icon type="github" />
              </a>
            </Tooltip>
          </h2>
          <h1>
            I design and build
            <br />software and games.
          </h1>
          <a onClick={this.handleSeeMyWorkClick}>
            See My Work &rarr;
          </a>
        </div>
      </section>
    );
  }
}
